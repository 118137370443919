import styled from 'styled-components'

const contextMenuPadding = 8

export const ContextMenu = styled.ul`
  padding: ${contextMenuPadding / 2}px;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  border-radius: 4px;
  z-index: 1000;
`

export const ContextMenuItem = styled.li<{ disabled?: boolean }>`
  font-size: 14px;
  padding: 6px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  &:hover {
    color: var(--theme-ui-colors-button-text);
    background-color: var(--theme-ui-colors-secondary);
  }
`

export const ContextMenuGroup = styled.fieldset`
  padding: 0 8px;
  transition: all 0.3s ease;
  padding: 0;
  border: 0;

  &:not(:last-child) {
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 4px;
  }

  legend {
    padding: 0;
    padding-left: 8px;
    margin: 0;
    font-size: 12px;
    color: #d1d1d1;
    padding-top: 4px;
    margin-bottom: 4px;
  }
`
