// @ts-ignore
import { Swiper, SwiperProps } from 'swiper/swiper-react'

import 'swiper/swiper.min.css'

import React, { useRef } from 'react'
import { StyledCustomSwiper } from './styles'
import {
  RiArrowDropLeftLine,
  RiArrowDropRightLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
} from '@remixicon/react'
import { NavigationButton } from '~/componentsV2/navigationButton'

interface CustomSwiperProps extends SwiperProps {
  className?: string
  children?: React.ReactNode
  arrowSpacing?: number
  extraButtons?: React.ReactNode
  ref?: React.RefObject<HTMLDivElement>
}

export const CustomSwiper: React.FC<CustomSwiperProps> = ({
  className,
  children,
  direction,
  navigation,
  arrowSpacing = 0,
  extraButtons,
  ref,
  ...props
}) => {
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)

  return (
    <StyledCustomSwiper className={className} ref={ref}>
      <Swiper
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        direction={direction}
        {...props}
      >
        {children}
      </Swiper>
      {extraButtons}
      <NavigationButton ref={prevRef} direction={direction} action="prev" spacing={arrowSpacing}>
        <RiArrowLeftSLine />
      </NavigationButton>
      <NavigationButton ref={nextRef} direction={direction} action="next" spacing={arrowSpacing}>
        <RiArrowRightSLine />
      </NavigationButton>
    </StyledCustomSwiper>
  )
}
