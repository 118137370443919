/* eslint-disable no-console */
export const mountCanvas = async (imageUrl: string, watermarkText: string, logoUrl: string) => {
  const loadImage = (src: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'Anonymous'
      img.src = src
      img.onload = () => resolve(img)
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`))
    })

  try {
    const [image, logo] = await Promise.all([loadImage(imageUrl), loadImage(logoUrl)])

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) throw new Error('Canvas context is not available.')

    const extraHeight = 120
    canvas.width = image.width
    canvas.height = image.height + extraHeight

    ctx.drawImage(image, 0, 0)

    ctx.fillStyle = 'rgba(255, 255, 255, 1)'
    ctx.fillRect(0, canvas.height - extraHeight, canvas.width, extraHeight)

    const logoWidth = 400
    const logoHeight = (logo.height / logo.width) * logoWidth
    const logoX = 20
    const logoY = canvas.height - logoHeight - 40
    ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight)

    ctx.font = '32px Arial'
    ctx.fillStyle = 'black'
    ctx.textAlign = 'left'
    const textX = logoWidth + 32
    const textY = canvas.height - 50
    ctx.fillText(watermarkText, textX, textY)

    return canvas
  } catch (error) {
    console.error('Error adding watermark and downloading:', error)
  }
}

export const addWatermarkAndDownload = async (
  imageUrl: string,
  watermarkText: string,
  logoUrl: string,
  fileName = 'image_with_watermark.png'
) => {
  const canvas = await mountCanvas(imageUrl, watermarkText, logoUrl)

  if (!canvas) return

  const link = document.createElement('a')
  link.href = canvas.toDataURL()
  link.download = fileName
  link.click()
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

export const copyImageToClipboard = async (
  imageUrl: string,
  watermarkText: string,
  logoUrl: string
) => {
  const canvas = await mountCanvas(imageUrl, watermarkText, logoUrl)
  if (!canvas) return

  // Converte o canvas para Blob e copia para a área de transferência
  canvas.toBlob(blob => {
    if (!blob) return
    const item = new ClipboardItem({ 'image/png': blob })
    navigator.clipboard
      .write([item])
      .then(() => console.info('Image copied to clipboard'))
      .catch(err => console.error('Failed to copy image to clipboard:', err))
  }, 'image/png')
}

export const openImageInNewTab = async (
  imageUrl: string,
  watermarkText: string,
  logoUrl: string
) => {
  const canvas = await mountCanvas(imageUrl, watermarkText, logoUrl)
  if (!canvas) return

  const newTab = window.open()
  if (newTab) {
    newTab.document.body.style.margin = '0'
    newTab.document.body.style.backgroundColor = 'hsl(0, 0%, 90%)'
    newTab.document.body.style.display = 'flex'
    newTab.document.body.style.alignItems = 'center'
    newTab.document.body.style.justifyContent = 'center'
    newTab.document.body.style.height = '100vh'

    const img = new Image()
    img.src = canvas.toDataURL('image/png')
    img.style.maxWidth = '100%'
    img.style.maxHeight = '100%'
    newTab.document.body.appendChild(img)
  }
}
