import React, { useState, useRef, useEffect, forwardRef } from 'react'
import { ContextMenu, ContextMenuGroup, ContextMenuItem } from './styles'

interface Action {
  label: string
  disabled?: boolean
  onClick: () => void
  group?: string
}

interface CustomContextMenuProps {
  children?: React.ReactNode
  actions: Action[]
}

const CustomContextMenu = forwardRef<HTMLDivElement, CustomContextMenuProps>(
  ({ children, actions }, ref) => {
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const menuRef = useRef<HTMLUListElement>(null)

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
      if (ref && 'current' in ref && ref.current?.contains(e.target as Node)) {
        e.preventDefault()

        const parentRect = ref.current.getBoundingClientRect()
        let x = e.clientX - parentRect.left
        let y = e.clientY - parentRect.top

        const menuWidth = 200
        const menuHeight = 150
        const viewportWidth = window.innerWidth
        const viewportHeight = window.innerHeight

        if (x + menuWidth > viewportWidth) {
          x = viewportWidth - menuWidth - 10
        }

        if (y + menuHeight > viewportHeight) {
          y = viewportHeight - menuHeight - 10
        }

        setMenuPosition({ x, y })
        setIsMenuVisible(true)
      }
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setIsMenuVisible(false)
      }
    }

    useEffect(() => {
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }, [])

    const defaultActions: Action[] = [
      {
        label: 'Back',
        onClick: () => {
          window.history.back()
        },
        group: 'default',
      },
      {
        label: 'Forward',
        disabled: typeof window !== 'undefined' && !window.history?.state?.forward,
        onClick: () => {
          window.history.forward()
        },
      },
      {
        label: 'Reload',
        onClick: () => {
          window.location.reload()
        },
      },
      {
        label: 'Close',
        onClick: () => {
          setIsMenuVisible(false)
        },
      },
    ]

    const actionGroup = [...defaultActions, ...actions].reduce((acc, action) => {
      acc[action?.group || 'default'] = acc[action?.group || 'default'] || []
      acc[action?.group || 'default'].push(action)
      return acc
    }, {} as Record<string, Action[]>)

    return (
      <div
        ref={ref}
        className="custom-context-menu"
        onContextMenu={handleContextMenu}
        style={{
          display: 'grid',
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          position: 'relative',
        }}
      >
        {children}

        {isMenuVisible && (
          <ContextMenu
            ref={menuRef}
            style={{
              top: menuPosition.y,
              left: menuPosition.x,
            }}
          >
            {Object.entries(actionGroup).map(([group, actions], index) => (
              <ContextMenuGroup key={group}>
                {group !== 'default' && <legend>{group}</legend>}
                {actions.map((action, index) => (
                  <ContextMenuItem
                    key={index}
                    role="button"
                    disabled={action.disabled}
                    onClick={() => {
                      action.onClick()
                      setIsMenuVisible(false)
                    }}
                  >
                    {action.label}
                  </ContextMenuItem>
                ))}
              </ContextMenuGroup>
            ))}
          </ContextMenu>
        )}
      </div>
    )
  }
)

export default CustomContextMenu
