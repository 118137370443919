/** @jsx jsx */
import { jsx, Flex, Heading, Box, Image, Text } from 'theme-ui'
import { FC, Fragment } from 'react'
import { Maybe, ProductItemType, ProductVariantType } from '~/@types/models'
import { Link } from 'gatsby'
import urls from '~/common/urls'
import { space } from '~/gatsby-plugin-theme-ui'
import { imageClassByType } from '~/utils/product'
import ImageWrapper from '../image-wrapper'

type Props = {
  variant?: string
  product: ProductItemType
  visibleVariantsCount?: number
  productVariant?: Maybe<ProductVariantType>
  collectionHandle: string
  onItemClick?: () => void
}

type UseProductLinkType = {
  product: ProductItemType
  collectionHandle: string
}

const useProductLink = ({ product, collectionHandle }: UseProductLinkType) => {
  let linkProps = {}

  if (collectionHandle && collectionHandle.indexOf('sale') >= 0) {
    linkProps = {
      ...linkProps,
      state: {
        comesFromSaleCollections: true,
      },
    }
  }

  const productLink = `${urls.pages.productDetail}/${product.handle}`

  return {
    linkProps,
    productLink,
  }
}

const AntiqueRugsProductItem: FC<Props> = ({
  variant = 'primary',
  product,
  collectionHandle,
  onItemClick,
}) => {
  const { linkProps, productLink } = useProductLink({
    product,
    collectionHandle,
  })

  const collectionImageSource =
    product.images.length > 1
      ? product?.images[1]?.srcMobileSmall
      : product?.mainImage?.srcMobileSmall

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
      }}
      onContextMenu={() => onItemClick && onItemClick()}
      onClick={() => onItemClick && onItemClick()}
    >
      {product.mainImage && (
        <Link
          className="product-image"
          to={productLink}
          {...linkProps}
          sx={{
            maxHeight: 'calc(100% - 48px)',
            overflow: 'hidden',
          }}
        >
          <ImageWrapper ratio={404 / 541} center>
            <Image
              className={`${imageClassByType(product)} ${product.handle}`}
              src={collectionImageSource}
              alt={product.mainImage.altText || product.title || 'Product Image'}
              onMouseOver={(e: { currentTarget: { src: string } }) => {
                e.currentTarget.src = product?.mainImage?.srcMobileSmall
              }}
              onMouseOut={(e: { currentTarget: { src: string } }) => {
                e.currentTarget.src = product?.images[1]?.srcMobileSmall
              }}
              sx={{
                zIndex: 1,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </ImageWrapper>
        </Link>
      )}
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          px: '10px',
          flexDirection: ['column', 'row'],
          mt: 11,
        }}
      >
        <Box
          sx={{
            textAlign: ['center', 'left'],
            width: ['100%', 'initial'],
          }}
        >
          <Link
            to={productLink}
            sx={{
              textDecoration: 'none',
            }}
            {...linkProps}
          >
            <Heading
              as="h3"
              variant="title7"
              sx={{
                variant: `variants.product.${variant}.title`,
                textTransform: 'uppercase',
              }}
            >
              {/* {product.metafields.antiqueName}, {product.metafields.origin?.[0]},{' '}
              {product.metafields.filterSize?.[0]} */}
              {product.title}
            </Heading>
            <Text
              sx={{
                fontSize: '11px',
                fontFamily: 'helvetica',
                fontWeight: '200',
                color: '#595858',
              }}
            >
              {product.cheapestVariant?.sku}
            </Text>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export default AntiqueRugsProductItem
