/** @jsx jsx **/
import { useState, Fragment } from 'react'
import { jsx, Text } from 'theme-ui'

import { ProductItemComponentType } from '~/@types/models'

import CustomQuickShipFormDialog from '~/components/custom-quick-ship-form-dialog'
import { Button } from '~/components/button'

import { hubspotFormIds } from '~/common/hubspot'
import { HubspotForm } from '~/utils/hubspotForm'

interface Props {
  product: ProductItemComponentType
  formId?: string
  activeText?: boolean
  textLabel?: string
  triggerProductName?: string
}

const ReqDrawingFiles = ({ product, formId, activeText, textLabel, triggerProductName }: Props) => {
  const [isFormDialogVisible, setFormDialogVisible] = useState<boolean>(false)
  const id = !formId ? hubspotFormIds.drawingFiles : formId
  const productName = !triggerProductName ? 'drawing_files_product_name' : triggerProductName

  return (
    <Fragment>
      {activeText ? (
        <Text
          sx={{
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: ['inherit'],
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={() => setFormDialogVisible(true)}
        >
          {textLabel}
        </Text>
      ) : (
        <Button
          sxProps={{
            marginTop: 50,
            width: ['100%'],
          }}
          label="Inquire"
          onClick={() => setFormDialogVisible(true)}
        />
      )}

      <CustomQuickShipFormDialog
        showDialog={isFormDialogVisible}
        close={() => setFormDialogVisible(false)}
        title="REQUEST DRAWING FILES"
      >
        <HubspotForm
          formId={id}
          onReady={() => {
            const productNameInput = document.getElementsByName(productName)[0]

            if (productNameInput) {
              productNameInput.value = product.title
            }
          }}
        />
      </CustomQuickShipFormDialog>
    </Fragment>
  )
}

export default ReqDrawingFiles
